<template>
    <div class="flex flex-col gap-4">
        <div>
            <h2 class="text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white">
                Create an organisation
            </h2>
            <p class="text-sm text-neutral-500">Create a new organisation to manage your club and events.</p>
        </div>

        <div>
            <div class="relative flex flex-wrap w-full h-full">
                <div class="relative w-full max-w-sm lg:mb-0">
                    <div class="relative">
                        <div>
                            <form id="login" @submit.prevent="onSubmit" class="space-y-6">
                                <CommonInput label="Name" placeholder="Name" name="name" />

                                <UButton
                                    :loading="isSubmitting"
                                    type="submit"
                                    block
                                    trailing
                                    icon="i-tabler-arrow-right"
                                >
                                    Create
                                </UButton>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import ApiError from '~/api/models/ApiError';

const api = useApi();

const organisation: App.Data.OrganisationData = reactive({
    name: '',
});

const { handleSubmit, meta, values, isSubmitting, setErrors, errors } = useForm({
    initialValues: organisation,
});

const emit = defineEmits(['success']);
const error = ref<string>('');
const toast = useToast();
const onSubmit = handleSubmit(async (values) => {
    try {
        await api.v1.organisation.create(values);
        toast.add({
            title: 'Created organisation!',
            description: 'You can now start adding members.',
            ui: {
                progress: {
                    base: 'absolute bottom-0 end-0 start-0 h-1',
                    background: 'bg-green-300 dark:bg-green-300',
                },
            },
        });
        createOrganisationDialog.close();
        emit('success');
    } catch (apiError) {
        if (apiError instanceof ApiError) {
            setErrors(apiError.errors);
            error.value = apiError.message;
            toast.add({
                title: 'Oops!',
                description: apiError.message,
                ui: {
                    progress: {
                        base: 'absolute bottom-0 end-0 start-0 h-1',
                        background: 'bg-red-300 dark:bg-red-300',
                    },
                },
            });
        }
    }
});
</script>

<style scoped></style>
